export const PublishTpl = {
    cardBuy: {
        value: 0,
        label: "免单卡购买页",
    },
    fastPage: {
        value: 1,
        label: "极速首页",
    },
    videoPage: {
        value: 2,
        label: "视频首页",
    },
};