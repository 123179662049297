<template>
  <el-card style="min-height: 45rem">
    <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="min-height: 20rem">
      设置首页模版
      <div style="margin-top: 2rem;border: 1px solid #ededed;padding: 2rem">
        <div class="flex-def flex-cCenter">
          <el-radio-group v-model="log.tpl">
            <el-radio border v-for="(item,key) in tplOpts" :key="key" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </div>
        <div class="y-desc" style="margin-top: 1rem;color: red;font-weight: 600">切换首页模版后小程序端需重新发布</div>
        <div class="flex-def flex-zCenter" style="margin-top: 1rem">
          <el-button @click="edit" style="width: 100%" size="mini">确认修改</el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import {PublishTpl} from "@/view/card/plugin/home-page/state";

export default {
  name: "publish-log",
  data() {
    return {
      log: {
        tpl: 0,
      },

      tplOpts: PublishTpl,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.homePage.publishLog().then(res => {
        this.log = res;
      })
    },
    edit() {
      this.$u.api.card.plugin.homePage.publishLogEdit(this.log).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  },
}
</script>

<style scoped>

</style>